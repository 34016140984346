<template>
  <div>
    <navbar title="投诉与建议"></navbar>
    <div class="content">
      <div class="header">您所要反应的问题</div>
      <textarea
        v-model="question"
        class="txt"
        name=""
        id=""
        cols="30"
        rows="10"
        placeholder="请输入您的投诉与建议，在200个字符以内"
      ></textarea>
      <div class="title" v-if="row.status == 1">平台回复</div>
      <div class="message" v-if="row.status == 1">
        <img class="img" src="../assets/xx.png" alt="" />
        <div class="item">
          <div class="item1">
            <span v-html="row.answer"></span>
          </div>
          <div class="item2">
            <img src="../assets/time.png" alt="" />{{ row.answer_time }}
          </div>
        </div>
      </div>
      <div class="btn" @click="btn" v-if="flag">保存</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      question: "",
      row: {
        status: 2,
      },
      flag: true,
    };
  },
  computed: {
    ...mapState({
      userinfo: (state) => state.userinfo,
      invitationinif: (state) => state.invitationinif,
    }),
  },
  methods: {
    ...mapActions(["getsuggestAdd"]),
    btn() {
      let that = this;
      if (this.question.trim() == "") {
        this.$toast.fail("内容不能为空");
        return;
      }
      //type == 1 建议
      //type == 2 投诉
      if (this.$route.query.is_type == 2) {
        this.getsuggestAdd({
          member_id: this.userinfo.id, //用户id
          question: this.question,
          complaint_id: this.invitationinif.id,
          type: 1,
        }).then((res) => {
          if (res.SuccessCode == 200) {
            this.$toast.success("反馈成功");
            setTimeout(() => {
              that.$router.go(-1);
            }, 500);
          }
        });
      } else {
        this.getsuggestAdd({
          member_id: this.userinfo.id, //用户id
          question: this.question,
          type: 1,
        }).then((res) => {
          if (res.SuccessCode == 200) {
            this.$toast.success("反馈成功");
            setTimeout(() => {
              that.$router.go(-1);
            }, 500);
          }
        });
      }
    },
  },
  mounted() {
    console.log(this.$route.query);
    if (this.$route.query.is_type == 1) {
      this.flag = false;
      this.row = this.$route.query;
      this.question = this.row.question;
    }
  },
};
</script>

<style lang='scss' scoped>
.content {
  margin: 0.34rem 0 0 0.24rem;
  padding: 0.36rem;
  box-sizing: border-box;
  width: 7.1rem;

  background: #ffffff;
  box-shadow: 0rem 0.02rem 0.16rem 0rem rgba(0, 0, 0, 0.19);
  border-radius: 0.12rem;
  .header {
    padding-bottom: 0.24rem;
    font-size: 0.34rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141f33;
    border-bottom: 0.01rem solid #ebebeb;
  }
  .txt {
    margin-top: 0.1rem;
    font-size: 0.26rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #7c7c7c;
    border: 0 none;
    width: 100%;
    height: 3rem;
    resize: none;
    border-bottom: 0.01rem solid #ebebeb;
  }
  .title {
    font-size: 0.32rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #141f33;
  }
  .message {
    margin-top: 0.2rem;
    display: flex;
    padding-bottom: 0.24rem;
    border-bottom: 0.01rem solid #ebebeb;
    .img {
      width: 0.76rem;
      height: 0.76rem;
    }
    .item {
      margin-left: 0.35rem;
      .item1 {
        font-size: 0.26rem;
        line-height: 0.46rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #7c7c7c;
      }
    }
    .item2 {
      margin-top: 0.2rem;
      font-size: 0.24rem;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #cdcdcd;
      img {
        margin-right: 0.15rem;
        vertical-align: top;
        width: 0.28rem;
      }
    }
  }
  .btn {
    margin-top: 1rem;
    width: 6.4rem;
    height: 0.9rem;
    line-height: 0.9rem;
    text-align: center;
    background: #ffffff;
    border-radius: 0.09rem;
    border: 0.01rem solid #4f7acd;
    font-size: 0.34rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #4875cb;
  }
}
</style>